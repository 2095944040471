import reportException from '../../shared/lib/report-exception';

export const createCookie = ({
  name,
  value,
  days,
}) => {
  try {
    let expires = '';

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toGMTString()}`;
    }

    const domain = document.location.hostname.replace('www.', '');

    document.cookie = `${name}=${value}${expires}; path=/; domain=.${domain}`;
  } catch (err) {
    reportException(err, {}, 'create-cookie');
  }
};

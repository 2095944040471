import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { push } from 'connected-react-router';
import { isEmpty, identity, path as ramdaPath } from 'ramda';

import {
  setHistoricalSearchValues,
  setByAirlineSecondaryAction,
} from '../../../redux/Single/actions';
import SearchSectionContainer from '../../../../../shared/components/SearchSectionContainer/SearchSectionContainer';
import FSDatePicker from '../../../../../shared/components/FSDatePicker/FSDatePicker';
import flexErrors from '../../../../../src/constants/flex-errors';
import AirlineSelect from '../../../../../src/components/lib/AirlineSelect';
import AirportSelect from '../../../../../src/components/lib/AirportSelect';
import { dateFormatString } from '../../../../../src/lib/date-time-format';
import componentBase from '../../../../../src/lib/component-base';
import { getAirportCodeSimple,getAirportCodeSimpleHistory, getAirlineCodeSimpleSearch } from '../../../../../shared/lib/airport-code-helpers';

@componentBase('HistoricalByAirportSearch')
export default class HistoricalByAirportSearch extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
    arrivalAirport: PropTypes.object,
    byAirportAirline: PropTypes.object,
    departureAirport: PropTypes.object,
    airportSearchDepartureDate: PropTypes.object,
    handleAirlineByRouteChange: PropTypes.func,
    handleAirportArrivalChange: PropTypes.func,
    handleAirportDepartureChange: PropTypes.func,
    handleDepartureDateChange: PropTypes.func,
    user: PropTypes.object,
    byAirlineSecondaryAction: PropTypes.bool,
  }

  constructor(props, context) {
    super(props, context);
    this.dateChangeHandler = this.dateChangeHandler.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.state = {
      errorText: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { byAirlineSecondaryAction, dispatch } = nextProps;

    if (byAirlineSecondaryAction) {
      dispatch(setByAirlineSecondaryAction(false));
    }
  }

  onAirlineChange = (option) => {
    this.props.handleAirlineByRouteChange(option);
  }

  onDelete = (action, value) => {
    this.props.dispatch(action(value));
  }

  onInputChange = (option, stateKey) => {
    const newState = {};
    newState[stateKey] = option && option.value;
    this.props.dispatch(setHistoricalSearchValues(newState));
  }

  /* eslint-disable no-underscore-dangle */
  getAirportCodes = airport => (
    airport &&
    airport.value &&
    airport.value._source &&
    getAirportCodeSimpleHistory(airport.value._source)
  );

  getAirlineCodes = airline => (
    airline &&
    airline.value &&
    airline.value._source &&
    getAirlineCodeSimpleSearch(airline.value._source)
  );

  handleAirportDepartureChange = (option) => {
    this.props.handleAirportDepartureChange(option);
  }

  handleAirportArrivalChange = (option) => {
    this.props.dispatch.handleAirportArrivalChange(option);
  }

  /* eslint-disable no-underscore-dangle */
  dateChangeHandler = (date) => {
    this.props.handleDepartureDateChange(date, 'airportSearch');
  }

  submit = (e) => {
    e && e.preventDefault();
    const url = this.buildUrl();

    const byRoute = this.props.departureAirport && this.props.arrivalAirport;

    if (this.validateAirportSearch() && url) {
      this.setState({ errorText: null }, () => {
        const topOfStackHistory = ramdaPath(['location', 'pathname'], this.props.history);
        if (url && topOfStackHistory !== url) {
          this.context.reportButtonPress(`Search - By ${byRoute ? 'Route' : 'Airport'}`);
          this.props.dispatch(push(url));
        }
      });
    } else if (this.errorText) {
      this.setState({ errorText: this.errorText });
    }
  }

  validateAirportSearch = () => {
    const {
      departureAirport,
      arrivalAirport,
      airportSearchDepartureDate,
    } = this.props;
    this.errorText = null;

    if (!airportSearchDepartureDate.isValid()) {
      this.errorText = flexErrors('BAD_MOMENT');
    } else if (isEmpty(departureAirport) && isEmpty(arrivalAirport)) {
      this.errorText = flexErrors('HISTORICAL_AIRPORT_SEARCH_NO_INFORMATION_ENTERED');
    }

    return this.errorText === null;
  }

  buildUrl = () => {
    const {
      airportSearchDepartureDate,
      arrivalAirport,
      byAirportAirline,
      departureAirport,
    } = this.props;

    let path;
    const departureAirportCode = this.getAirportCodes(departureAirport);
    const arrivalAirportCode = this.getAirportCodes(arrivalAirport);
    const airportSearchDepartureDateFormatted = moment(airportSearchDepartureDate).format('YYYY/MM/DD');
    const basePath = '/historical-flight';

    if (departureAirportCode && !arrivalAirportCode) {
      path = `${basePath}/departing/${departureAirportCode}/${airportSearchDepartureDateFormatted}`;
    } else if (arrivalAirportCode && !departureAirportCode) {
      path = `${basePath}/arriving/${arrivalAirportCode}/${airportSearchDepartureDateFormatted}`;
    } else if (departureAirportCode && arrivalAirportCode) {
      path = `${basePath}/route/${departureAirportCode}/${arrivalAirportCode}/${airportSearchDepartureDateFormatted}`;
    }

    if (byAirportAirline) {
      const airlineCode = this.getAirlineCodes(byAirportAirline);
      path = `${path}/${airlineCode}`;
    }

    return path;
  };

  render = () => {
    const {
      airportSearchDepartureDate,
      arrivalAirport,
      byAirlineSecondaryAction,
      byAirportAirline,
      departureAirport,
      dispatch,
    } = this.props;

    let error = '';
    if (this.state.errorText) {
      error = (
        <div className='search-error-text-block'>
          <p className='search-error-text'>{this.state.errorText}</p>
        </div>
      );
    }

    return (
      <form onSubmit={this.submit}>
        <SearchSectionContainer
          searchAction={this.submit}
          subscriptionActive
          title='Search by Airport or Route'
        >
          <div className='historical-search-airport-routes-search-fields'>
            {error}
            <div className='historical-search-half-fields-container'>
              <div className='historical-search-field'>
                <p>Departure Airport</p>
                <AirportSelect
                  isHistory={true}
                  inputTitle='Departure Airport'
                  name='departureAirport'
                  onChange={(option, triggeredByEnter) =>
                    dispatch(setHistoricalSearchValues(
                        { departureAirport: option },
                        triggeredByEnter,
                        false,
                      ))
                  }
                  airport={departureAirport}
                  secondaryAction={
                    byAirlineSecondaryAction ?
                    this.submit : identity
                  }
                  onDelete={() => (
                    this.onDelete(
                      setHistoricalSearchValues,
                      { departureAirport: {} },
                    )
                  )
                }
                />
              </div>
              <div className='historical-search-field'>
                <p>Arrival Airport</p>
                <AirportSelect
                  isHistory={true}
                  inputTitle='Arrival Airport'
                  name='arrivalAirport'
                  onChange={(option, triggeredByEnter) =>
                    dispatch(setHistoricalSearchValues(
                      { arrivalAirport: option },
                      triggeredByEnter,
                      false,
                    ))
                  }
                  airport={arrivalAirport}
                  secondaryAction={
                    byAirlineSecondaryAction ?
                    this.submit : identity
                  }
                  onDelete={() => (
                    this.onDelete(
                      setHistoricalSearchValues,
                      { arrivalAirport: {} },
                    )
                  )
                }
                />
              </div>
            </div>
            <div className='historical-search-half-fields-container'>
              <div className='historical-search-field'>
                <p>Airline</p>
                <AirlineSelect
                  name='airline'
                  isHistory={true}
                  airline={byAirportAirline}
                  onChange={(option, triggeredByEnter) =>
                    dispatch(setHistoricalSearchValues(
                      { byAirportAirline: option },
                      triggeredByEnter,
                      false,
                    ))
                  }
                  secondaryAction={
                    byAirlineSecondaryAction ?
                    this.submit : identity
                  }
                  onDelete={() => (
                    this.onDelete(
                      setHistoricalSearchValues,
                      { byAirportAirline },
                    )
                  )
                }
                />
              </div>
              <div className='historical-search-field'>
                <p>Date</p>
                <FSDatePicker
                  className='advanced-search-text-field full-width'
                  style={{ width: '100%' }}
                  key='airportSearchDepartureDate'
                  locale='en'
                  dateFormat={dateFormatString(this.props.user)}
                  selected={airportSearchDepartureDate}
                  maxDate={moment()}
                  minDate={moment('20060207', 'YYYYMMDD')}
                  onChange={this.dateChangeHandler}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  readOnly={false}
                />
              </div>
            </div>
          </div>
        </SearchSectionContainer>
      </form>
    );
  }
}

import { NAME } from './constants';

export const SET_SERVER_TIME = `${NAME}/SET_SERVER_TIME`;
export const RESET_AUTH_STATE = `${NAME}/RESET_AUTH_STATE`;
export const LOAD_SESSION = `${NAME}/LOAD_SESSION`;

export const LOAD = `${NAME}/LOAD`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAIL = `${NAME}/LOAD_FAIL`;
export const LOAD_END = `${NAME}/LOAD_END`;

export const LOGIN = `${NAME}/LOGIN`;
export const LOGIN_SUCCESS = `${NAME}/LOGIN_SUCCESS`;
export const LOGIN_FAIL = `${NAME}/LOGIN_FAIL`;
export const LOGIN_RECAPTCHA = `${NAME}/LOGIN_RECAPTCHA`;

export const SIGNUP = `${NAME}/SIGNUP`;
export const SIGNUP_SUCCESS = `${NAME}/SIGNUP_SUCCESS`;
export const SIGNUP_FAIL = `${NAME}/SIGNUP_FAIL`;

export const REGISTRATION_DATA_CLEAR = `${NAME}/REGISTRATION_DATA_CLEAR`;
export const REGISTRATION_EMAIL = `${NAME}/REGISTRATION_EMAIL`;
export const REGISTRATION_PASSWORD = `${NAME}/REGISTRATION_PASSWORD`;
export const REGISTRATION_PASSWORD_CONFIRMATION = `${NAME}/REGISTRATION_PASSWORD_CONFIRMATION`;
export const REGISTRATION_TERMS_AND_CONDITIONS = `${NAME}/REGISTRATION_TERMS_AND_CONDITIONS`;
export const REGISTRATION_RECAPTCHA = `${NAME}/REGISTRATION_RECAPTCHA`;
export const REGISTRATION_SIGNUP = `${NAME}/REGISTRATION_SIGNUP`;
export const REGISTRATION_SUCCESS = `${NAME}/REGISTRATION_SUCCESS`;
export const REGISTRATION_FAIL = `${NAME}/REGISTRATION_FAIL`;
export const USER_SERVICE_REGISTRATION_FAIL = `${NAME}/USER_SERVICE_REGISTRATION_FAIL`;
export const INVALIDATE_RECAPTCHA = `${NAME}/INVALIDATE_RECAPTCHA`;

export const LOGOUT = `${NAME}/LOGOUT`;
export const LOGOUT_SUCCESS = `${NAME}/LOGOUT_SUCCESS`;
export const LOGOUT_FAIL = `${NAME}/LOGOUT_FAIL`;

export const FORGOT_PASSWORD = `${NAME}/FORGOT_PASSWORD`;
export const FORGOT_PASSWORD_SUCCESS = `${NAME}/FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_FAIL = `${NAME}/FORGOT_PASSWORD_FAIL`;
export const FORGOT_PASSWORD_ERROR = `${NAME}/FORGOT_PASSWORD_ERROR`;
export const FORGOT_PASSWORD_RECAPTCHA = `${NAME}/FORGOT_PASSWORD_RECAPTCHA`;

export const CHECK_BUSINESSUSER = `${NAME}/BUSINESSUSER`;
export const CHECK_BUSINESSUSER_SUCCESS = `${NAME}/BUSINESSUSER_SUCCESS`;
export const CHECK_BUSINESSUSER_ERROR = `${NAME}/BUSINESSUSER_ERROR`;

export const SEND_EMAIL_VERIFICATION = `${NAME}/SEND_EMAIL_VERIFICATION`;
export const SEND_EMAIL_VERIFICATION_SUCCESS = `${NAME}/SEND_EMAIL_VERIFICATION_SUCCESS`;
export const SEND_EMAIL_VERIFICATION_FAIL = `${NAME}/SEND_EMAIL_VERIFICATION_FAIL`;

export const GET_PAYMENT_HISTORY = `${NAME}/GET_PAYMENT_HISTORY`;
export const GET_PAYMENT_HISTORY_SUCCESS = `${NAME}/GET_PAYMENT_HISTORY_SUCCESS`;
export const GET_PAYMENT_HISTORY_FAIL = `${NAME}/GET_PAYMENT_HISTORY_FAIL`;

export const GET_PROFILE = `${NAME}/GET_PROFILE`;
export const GET_PROFILE_SUCCESS = `${NAME}/GET_PROFILE_SUCCESS`;
export const GET_PROFILE_FAIL = `${NAME}/GET_PROFILE_FAIL`;

export const UPDATE_PROFILE = `${NAME}/UPDATE_PROFILE`;
export const UPDATE_PROFILE_SUCCESS = `${NAME}/UPDATE_PROFILE_SUCCESS`;
export const UPDATE_PROFILE_FAIL = `${NAME}/UPDATE_PROFILE_FAIL`;

export const CHANGE_PASSWORD = `${NAME}/CHANGE_PASSWORD`;
export const CHANGE_PASSWORD_SUCCESS = `${NAME}/CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_FAIL = `${NAME}/CHANGE_PASSWORD_FAIL`;

export const DELETE_ACCOUNT = `${NAME}/DELETE_ACCOUNT`;
export const DELETE_ACCOUNT_SUCCESS = `${NAME}/DELETE_ACCOUNT_SUCCESS`;
export const DELETE_ACCOUNT_FAIL = `${NAME}/DELETE_ACCOUNT_FAIL`;

export const GET_BRAINTREE_CLIENT_TOKEN = `${NAME}/GET_BRAINTREE_CLIENT_TOKEN`;
export const GET_BRAINTREE_CLIENT_TOKEN_SUCCESS = `${NAME}/GET_BRAINTREE_CLIENT_TOKEN_SUCCESS`;
export const GET_BRAINTREE_CLIENT_TOKEN_FAIL = `${NAME}/GET_BRAINTREE_CLIENT_TOKEN_FAIL`;

export const GET_BRAINTREE_PLANS = `${NAME}/GET_BRAINTREE_PLANS`;
export const GET_BRAINTREE_PLANS_SUCCESS = `${NAME}/GET_BRAINTREE_PLANS_SUCCESS`;
export const GET_BRAINTREE_PLANS_FAIL = `${NAME}/GET_BRAINTREE_PLANS_FAIL`;

export const UPDATE_BILLING_ADDRESS = `${NAME}/UPDATE_BILLING_ADDRESS`;
export const UPDATE_BILLING_ADDRESS_SUCCESS = `${NAME}/UPDATE_BILLING_ADDRESS_SUCCESS`;
export const UPDATE_BILLING_ADDRESS_FAIL = `${NAME}/UPDATE_BILLING_ADDRESS_FAIL`;

export const CANCEL_BRAINTREE_SUBSCRIPTION = `${NAME}/CANCEL_BRAINTREE_SUBSCRIPTION`;
export const CANCEL_BRAINTREE_SUBSCRIPTION_SUCCESS = `${NAME}/CANCEL_BRAINTREE_SUBSCRIPTION_SUCCESS`;
export const CANCEL_BRAINTREE_SUBSCRIPTION_FAIL = `${NAME}/CANCEL_BRAINTREE_SUBSCRIPTION_FAIL`;

export const CREATE_BRAINTREE_SUBSCRIPTION = `${NAME}/CREATE_BRAINTREE_SUBSCRIPTION`;
export const CREATE_BRAINTREE_SUBSCRIPTION_SUCCESS = `${NAME}/CREATE_BRAINTREE_SUBSCRIPTION_SUCCESS`;
export const CREATE_BRAINTREE_SUBSCRIPTION_FAIL = `${NAME}/CREATE_BRAINTREE_SUBSCRIPTION_FAIL`;

export const UPDATE_PAYMENT_INFORMATION = `${NAME}/UPDATE_PAYMENT_INFORMATION`;
export const UPDATE_PAYMENT_INFORMATION_SUCCESS = `${NAME}/UPDATE_PAYMENT_INFORMATION_SUCCESS`;
export const UPDATE_PAYMENT_INFORMATION_FAILURE = `${NAME}/UPDATE_PAYMENT_INFORMATION_FAILURE`;

export const UPGRADE_SUBSCRIPTION = `${NAME}/UPGRADE_SUBSCRIPTION`;
export const UPGRADE_SUBSCRIPTION_SUCCESS = `${NAME}/UPGRADE_SUBSCRIPTION_SUCCESS`;
export const UPGRADE_SUBSCRIPTION_FAILURE = `${NAME}/UPGRADE_SUBSCRIPTION_FAILURE`;

export const ATTENTION_DIALOG = `${NAME}/ATTENTION_DIALOG`;
export const DISMISS_ATTENTION_DIALOG = `${NAME}/DISMISS_ATTENTION_DIALOG`;

export const CREATE_BRAINTREE_CREDITS_TRANSACTION = `${NAME}/CREATE_BRAINTREE_CREDITS_TRANSACTION`;
export const CREATE_BRAINTREE_CREDITS_TRANSACTION_SUCCESS = `${NAME}/CREATE_BRAINTREE_CREDITS_TRANSACTION_SUCCESS`;
export const CREATE_BRAINTREE_CREDITS_TRANSACTION_FAIL = `${NAME}/CREATE_BRAINTREE_CREDITS_TRANSACTION_FAIL`;

export const OUTAGE_PAGE_REDIRECT = `${NAME}/OUTAGE_PAGE_REDIRECT`;
export const SHOULD_REFRESH = `${NAME}/SHOULD_REFRESH`;

export const GET_PAYMENT_HISTORY_DETAIL = `${NAME}/GET_PAYMENT_HISTORY_DETAIL`;
export const GET_PAYMENT_HISTORY_DETAIL_SUCCESS = `${NAME}/GET_PAYMENT_HISTORY_DETAIL_SUCCESS`;
export const GET_PAYMENT_HISTORY_DETAIL_FAIL = `${NAME}/GET_PAYMENT_HISTORY_DETAIL_FAIL`;
export const GET_PAYMENT_HISTORY_DETAIL_ERROR = `${NAME}/GET_PAYMENT_HISTORY_DETAIL_ERROR`;

export const SELECT_NEW_ACCOUNT_PLAN = `${NAME}/SELECT_NEW_ACCOUNT_PLAN`;
export const UPDATE_ADDITIONAL_INFO_INPUT = `${NAME}/UPDATE_ADDITIONAL_INFO_INPUT`;
export const UPDATE_FORM_ERROR_STATE = `${NAME}/UPDATE_FORM_ERROR_STATE`;
export const HAS_CHECKED_TERMS_AND_CONDITIONS = `${NAME}/HAS_CHECKED_TERMS_AND_CONDITIONS`;
export const REGISTRATION_BILLING_INFO_BTN_DISABLED = `${NAME}/REGISTRATION_BILLING_INFO_BTN_DISABLED`;
export const GET_FIRST_TRANSACTIONID = `${NAME}/GET_FIRST_TRANSACTIONID`;
export const GET_FIRST_TRANSACTIONID_SUCCESS = `${NAME}/GET_FIRST_TRANSACTIONID_SUCCESS`;
export const GET_FIRST_TRANSACTIONID_FAIL = `${NAME}/GET_FIRST_TRANSACTIONID_FAIL`;
export const GO_TO_BILLING_INFORMATION = `${NAME}/GO_TO_BILLING_INFORMATION`;
export const SET_REGISTRATION_STAGE = `${NAME}/SET_REGISTRATION_STAGE`;
export const SET_PLAN_HOVERED = `${NAME}/SET_PLAN_HOVERED`;
export const HOSTED_FIELDS_DID_CREATE = `${NAME}/HOSTED_FIELDS_DID_CREATE`;
export const TOGGLE_ACCOUNT_PROFILE_DIALOGS = `${NAME}/TOGGLE_ACCOUNT_PROFILE_DIALOGS`;

export const UPDATE_REASON_FOR_UNSUBSCRIBING = `${NAME}/UPDATE_REASON_FOR_UNSUBSCRIBING`;
export const DELETE_ACCOUNT_SET_CHECKBOX = `${NAME}/DELETE_ACCOUNT_SET_CHECKBOX`;

export const REGISTRATION_FLOW_TOKEN = `${NAME}/REGISTRATION_FLOW_TOKEN`;
export const DELETE_REGISTRATION_FLOW_TOKEN = `${NAME}/DELETE_REGISTRATION_FLOW_TOKEN`;

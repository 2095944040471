/* eslint-disable no-empty */
import axios from 'axios';
import apiToken from '../helpers/ApiToken';
import { randomString } from '../../shared/lib/random';

const client = axios.create({
  baseURL: '/v2/api/search',
});

const url = path => `${path}?rqid=${randomString()}`;

const formatQuery = input => ({
  value: input,
});

const composeHeaders = (serverTime, WAFtoken) => {
  const headers = {
    Authorization: `Bearer ${apiToken(serverTime, true)}`,
  };
  if (WAFtoken) headers['X-Aws-Waf-Token'] = WAFtoken;
  return headers;
};

const getAwsWafIntegration = () => {
  // eslint-disable-next-line no-undef
  const AWSClient = AwsWafIntegration;
  return AWSClient;
};

const SearchSource = {
  async randomFlight(serverTime) {
    const randomFlightCallApi = (WAFtoken) => {
      const headers = composeHeaders(serverTime, WAFtoken);
      return client.get(url('/random-flight'), {
        headers,
      });
    };
    let token = null;
    try {
      const AWSClient = getAwsWafIntegration();
      token = await AWSClient.getToken();
    } catch (_) { }
    return randomFlightCallApi(token);
  },

  async structuredSearch(body, serverTime) {
    const structuredSearchCallApi = (WAFtoken) => {
      const headers = composeHeaders(serverTime, WAFtoken);
      return client.post(url('/structured-search'), formatQuery(body), {
        headers,
      });
    };
    let token = null;
    try {
      const AWSClient = getAwsWafIntegration();
      token = await AWSClient.getToken();
    } catch (_) { }
    return structuredSearchCallApi(token);
  },

  async airport(body, serverTime) {
    const airportCallApi = (WAFtoken) => {
      const headers = composeHeaders(serverTime, WAFtoken);
      return client.post(url('/airport-search'), {value: body.value, isHistory: body.isHistory}, {
        headers,
      });
    };
    let token = null;
    try {
      const AWSClient = getAwsWafIntegration();
      token = await AWSClient.getToken();
    } catch (_) { }
    return airportCallApi(token);
  },

  async airportLookup(airportCode, serverTime) {
    const airportLookupCallApi = (WAFtoken) => {
      const headers = composeHeaders(serverTime, WAFtoken);
      return client.get(url(`/airport/${airportCode}`), {
        headers,
      });
    };
    let token = null;
    try {
      const AWSClient = getAwsWafIntegration();
      token = await AWSClient.getToken();
    } catch (_) { }
    return airportLookupCallApi(token);
  },

  async airline(body, serverTime) {
    const airlineCallApi = (WAFtoken) => {
      const headers = composeHeaders(serverTime, WAFtoken);
      return client.post(url('/airline-search'), {value: body.value, isHistory: body.isHistory}, {
        headers,
      });
    };
    let token = null;
    try {
      const AWSClient = getAwsWafIntegration();
      token = await AWSClient.getToken();
    } catch (_) { }
    return airlineCallApi(token);
  },

  async airlineLookup(airlineCode, serverTime) {
    const airlineLookupCallApi = (WAFtoken) => {
      const headers = composeHeaders(serverTime, WAFtoken);
      return client.get(url(`/airline/${airlineCode}`), {
        headers,
      });
    };
    let token = null;
    try {
      const AWSClient = getAwsWafIntegration();
      token = await AWSClient.getToken();
    } catch (_) { }
    return airlineLookupCallApi(token);
  },
};

export default SearchSource;

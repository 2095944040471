import reportException from '../../shared/lib/report-exception';

export const readCookie = ({
  name,
}) => {
  try {
    let i;
    let x;
    let y;
    const arRcookies = document.cookie.split(';');
    for (i = 0; i < arRcookies.length; i++) {
      x = arRcookies[i].substr(0, arRcookies[i].indexOf('='));
      y = arRcookies[i].substr(arRcookies[i].indexOf('=') + 1);
      x = x.replace(/^\s+|\s+$/g, '');
      if (x === name) {
        return unescape(y);
      }
    }
  } catch (err) {
    reportException(err, {}, 'read-cookie');
  }
};

export const readCookieUserList = (name) => {
  const cookies = document.cookie.split("; ");
  const cookie = cookies.find(row => row.startsWith(name + "="));
  return cookie ? decodeURIComponent(cookie.split("=")[1]) : null;
}



import { getWebInstrumentations, initializeFaro, MetaApp } from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import React from 'react';

const Grafana = ({ grafanaURL, appName, appVersion, appEnv }) => {
  React.useEffect(() => {
    initializeFaro({
      url: grafanaURL,
      app: {
        name: appName,
        version: appVersion,
        environment: appEnv,
      },
      batching: { enabled: true, sendTimeout: 1000, itemLimit: 50 },
      isolate: true,
      instrumentations: [
        ...getWebInstrumentations({ captureConsole: false }),
        new TracingInstrumentation(),
      ],
      ignoreErrors: ['404'],
    });
  }, [grafanaURL, appName, appVersion, appEnv]);

  return null;
};

export default Grafana;

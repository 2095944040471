import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { parse } from 'qs';
import { CommonSection } from '@flightstats/component-lib';
import { actions } from '../redux';
import GoogleRecaptcha from '../../../shared/components/GoogleRecaptcha';
import FSFlatButton from '../../../src/components/lib/FSFlatButton';
import componentBase from '../../../src/lib/component-base';
import { isValidEmail } from '../../../src/helpers/Validators';
import { addMetaCanonical } from '../../../shared/lib/meta-tags';
import TextInput from '../../../shared/components/TextInput/TextInput';
import EmailTextInput,
{ textInputLabelStyle,
  textInputLabelErrorStyle,
  textInputSpanStyle,
  textInputInputStyle,
} from './shared/EmailTextInput';
import { getProfile } from '../redux/actions';
 import loaderIcon from '../../../static/images/loader-spinner.svg';
 import { debounce } from 'debounce';


const errorDisplayWording = (code) => {
  const val = code.general ? code.general : code;
  const valWithTooManyRequestsCase = (val && val.toString() && val.toString().toLowerCase() === 'too many requests') ? 'TOO_MANY_REQUESTS' : val;
  const wordings = {
    INVALID_PASSWORD: { message: 'Invalid email/password combination', type: 'email' },
    MISSING_EMAIL: { message: 'The email address provided is invalid', type: 'email' },
    INVALID_EMAIL: { message: 'The email address provided is invalid', type: 'email' },
    PASSWORD_REQUIRED: { message: 'A password must be provided', type: 'password' },
    MISSING_RECAPTCHA: {
      message: 'Recaptcha provided is invalid',
      type: 'recaptcha',
    },
    RECAPTCHA_ERROR: { message: 'recaptcha error, please try again later.' },
    UNKNOWN_USER: { message: 'There is no account associated with the user', type: 'email' },
    UNKNOWN_ERROR: { message: 'Error', type: 'email' },
    TOO_MANY_REQUESTS: { message: 'Too many attempts. Please try again later', type: 'email' },
  };

  const message = wordings[valWithTooManyRequestsCase] || wordings[val];

  if (message) {
    return message;
  }

  return {
    message: 'Error: Login temporarily unavailable',
    type: 'email',
  };
};

const parseQs = qs => parse(qs, { ignoreQueryPrefix: true });

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

const EmailTextInputWrapper = styled.div`
  flex: 1;
`;

const LoadingIndicator = styled.div`
  margin-left: 10px;
  margin-top:20px;
  color: #999;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
`;

const LoaderIcon = styled.img`
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


@connect(
  state => ({
    user: state.Account.user,
    loggedIn: state.Account.loggedIn,
    loginError: state.Account.loginError,
    loginRecaptchaValue: state.Account.loginRecaptchaValue,
    siteKey: state.App.siteKey,
    isBusinessUser: state.Account.isBusinessUser,
    isValidatingBusiness: state.Account.isValidatingBusiness
  }),
  actions,
)
@componentBase('Login')
export default class Login extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    user: PropTypes.object,
    resetAuthState: PropTypes.func,
    location: PropTypes.object,
    login: PropTypes.func,
    logout: PropTypes.func,
    loginError: PropTypes.array,
    loggedIn: PropTypes.bool,
    routerPush: PropTypes.func,
    siteKey: PropTypes.string,
    loginRecaptchaValue: PropTypes.string,
    loginRecaptcha: PropTypes.func,
    isBusinessUser:PropTypes.bool,
    isValidatingBusiness:PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    this.parsedQs = parseQs(props.location.search);

    this.redirected = false;

    this.setRef = this.setRef.bind(this);

    // refs
    this.email = null;
    this.password = null;
    this.rememberMe = null;

    this.state = {
      email: ''
    };

  this.debouncedCheckBusinessUserSubscriptionLevel = debounce(this.props.checkBusinessUserSubscriptionLevel, 500);

  }

  componentDidMount() {
    this.props.resetAuthState();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loggedIn) {
      if (!nextProps.user) {
        if (!this.userLostTheirSession) {
          /*
            no user but is logged in, hmmm...
            let's check if the session(s) has been deleted by
            calling getProfile().
            Any api call that would trigger the
            session checking middleware (and generate a 440)
            if session is missing would work here.
         */
          this.props.dispatch(getProfile());
          // flip internal boolean (userLostTheirSession) or else we could go infinite.
          this.userLostTheirSession = true;
        }
      } else if (!this.redirected) {
        let urlDestination = '/';

        const qs = parseQs(nextProps.location.search);

        if (qs.destination) {
          urlDestination = qs.destination;
        } else if (nextProps.user && !nextProps.user.verified) {
          urlDestination = '/account/verify';
        }

        this.redirected = true;
        this.props.resetAuthState();
        this.props.dispatch(push(urlDestination));
      }
    }
  }

  onCaptchaVerify = (value) => {
    // Called when recaptcha checked
    // Value is key from recaptcha
    if (value && !this.props.loginRecaptchaValue) {
      this.props.loginRecaptcha(value);
      this.handleSubmit();
    }
  };

  onEnterKeyPress = (e) => {
    if (e.charCode === 13) {
      this.handleSubmit(e);
    }
    this.lastKeyPressed = e.charCode;
  };

  getMetaTags = () => {
    const title = 'FlightStats - Login';
    const metaWithoutCanonical = {
      title,
    };
    const { location } = this.props;
    const pathname = location && location.pathname;
    return addMetaCanonical(metaWithoutCanonical, pathname);
  };

  setRef = (ref, refName) => {
    this[refName] = ref;
  };

  sanitizedEmail = () => (
    this.email.value && this.email.value.trim().toLowerCase()
  );

  handleSubmit = (e) => {
    e && e.preventDefault();

    const { rememberMe, password: passwordInput } = this;
    const reCaptcha = this.props.loginRecaptchaValue;
    if (this.props.isBusinessUser === true){
      this.props.login(
        this.sanitizedEmail(),
        passwordInput.value,
        rememberMe.checked,
        reCaptcha,
        this.props.isBusinessUser
      );
      return
    }
    if (reCaptcha) {
      this.props.login(
        this.sanitizedEmail(),
        passwordInput.value,
        rememberMe.checked,
        reCaptcha,
        this.props.isBusinessUser
      );
    } else {
      this.recaptchaRef.execute();
    }
  };

  recaptchaRefSet = (e) => {
    if (e) {
      this.recaptchaRef = e;
    }
  };
  checkBusinessUserSubscriptionLevel = (email) => {
      if(isValidEmail(email)){
        this.debouncedCheckBusinessUserSubscriptionLevel(email);
      }
  }

  validateEmail = () => {
    this.checkBusinessUserSubscriptionLevel(this.sanitizedEmail());
    if (this.lastKeyPressed !== 13 && isValidEmail(this.sanitizedEmail())) {
      this.props.resetAuthState();
    }
    this.lastKeyPressed = null;
  };

  inputBlurEVent = (e) => {
    this.setState({ email: this.email.value });
    this.checkBusinessUserSubscriptionLevel(this.email.value);
  }

  renderLoginForm = () => {
    const { loginError } = this.props;
    let error;
    let emailError = '';
    let passwordError = '';

    if (this.props.loginError) {
      const invalidPassword = loginError.filter(e => e === 'INVALID_PASSWORD');
      if (!invalidPassword) {
        this.email.value = '';
      }
      error = errorDisplayWording(loginError[0]);
      if (error) {
        switch (error.type) {
          case 'email':
            emailError = error.message;
            break;
          case 'password':
            passwordError = error.message;
            break;
          default:
            break;
        }
      }
    }

    return (
      <div className='innerContent'>
        <form
          onSubmit={this.handleSubmit}
          onKeyPress={this.onEnterKeyPress}
          method='POST'
        >
          <div className='innerForm'>
          <InputContainer>
            <EmailTextInputWrapper>
              <EmailTextInput
                errorMessage={emailError}
                inputRef={ref => this.setRef(ref, 'email')}
                validateEmail={this.validateEmail}
                inputBlurEVent={this.inputBlurEVent}
              />
            </EmailTextInputWrapper>
            {this.props.isValidatingBusiness && 
            <LoadingIndicator>
                <LoaderIcon src={loaderIcon} alt="Loading" />
              </LoadingIndicator>
              }
          </InputContainer>
           
            <p className='email-note'>
              NOTE: THIS EMAIL WILL BE THE EMAIL USED DURING REGISTRATION
            </p>
            <TextInput
              errorMessage={passwordError}
              inputClassName='account-input'
              inputRef={ref => this.setRef(ref, 'password')}
              inputStyle={textInputInputStyle}
              label='Password'
              labelErrorStyle={textInputLabelErrorStyle}
              labelStyle={textInputLabelStyle}
              placeholder='•••••••••'
              spanStyle={textInputSpanStyle}
              type='password'
            />
            {this.props.isBusinessUser ||  <div
              className='form-group captcha'
              id='captcha-holder'
              style={{ marginTop: '16px' }}
            >
              <GoogleRecaptcha
                sitekey={this.props.siteKey}
                size='invisible'
                ref={elem => this.recaptchaRefSet(elem)}
                onChange={this.onCaptchaVerify}
                badge='inline'
              />
            </div> }
           
            <div className='account-secondary-div'>
              <input
                type='checkbox'
                name='rememberMe'
                ref={ref => this.setRef(ref, 'rememberMe')}
                defaultChecked
                style={{ margin: '10px 3px 10px 0', display: 'inline-block' }}
              />
              <div
                className='account-input-title'
                style={{ display: 'inline-block', paddingLeft: '0', marginLeft: '2px' }}
              >
                Remember Me
              </div>
              <Link
                to='/account/forgot-password'
                className='account-link'
                style={{ margin: '5px 0', textDecoration: 'underline', float: 'right' }}
              >
                Forgot Password?
              </Link>
            </div>
          </div>
          <div className='innerPadding'>
            <FSFlatButton
              disabled={this.props.isValidatingBusiness}
              label='LOG IN'
              onClick={this.handleSubmit}
              style={FSFlatButton.style({ minWidth: '200px' })}
            />
          </div>
        </form>
        <div className='innerPadding'>
          <p className='account-no-account-text'>
            Don&apos;t have a FlightStats account?
          </p>
          <Link
            key='createNewAccount'
            to='/register/plan'
            className='account-link'
            style={{ textDecoration: 'underline' }}
          >
            Create a New Account
          </Link>
        </div>
      </div>
    );
  }

  render() {
    const meta = this.getMetaTags();

    return (
      <div className='LoginContainer'>
        <div className='sharedAccountFormContainer'>
          <Helmet {...meta} />
          <h1 className='accountTitle'>Login to FlightStats</h1>
          <CommonSection title='Login'>
            {this.renderLoginForm()}
          </CommonSection>
        </div>
      </div>
    );
  }
}

import { path } from 'ramda';
import { push } from 'connected-react-router';
import moment from 'moment-timezone';
import {createCookie} from '../../../src/utils/createCookie';
import {readCookieUserList} from '../../../src/utils/readCookie';

import {
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
} from '../../../src/helpers/Validators';
import { NAME } from './constants';
import { phoneNumberSanitizer } from '../../../src/helpers/Sanitizers';
import { validateCarrierAndPhoneState } from './selectors';
import * as t from './actionTypes';

export const loadSession = (session) => {
  if (session && session.user) {
    return {
      type: t.LOAD_SESSION,
      user: session.user,
    };
  }
  return {
    type: t.LOAD_SESSION,
  };
};

export const load = () => async (dispatch, getStore, client) => {
  dispatch({ type: t.LOAD });

  try {
    const result = await client.get('/auth/loadAuth');

    dispatch({
      type: t.LOAD_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.LOAD_FAIL,
      error,
    });
  }
};

export const startSpinner = () => ({
  type: t.LOAD,
});

export const endSpinner = () => ({
  type: t.LOAD_END,
});

const haltPolling = (id) => {
  if (typeof window === 'object') {
    const clear = window.clearInterval || clearInterval;
    clear && clear(id);
  }
};

export const getProfile = () => async (dispatch, getStore, client) => {
  dispatch({ type: t.GET_PROFILE });

  try {
    const result = await client.get('/auth/profile');

    return dispatch({
      type: t.GET_PROFILE_SUCCESS,
      result,
    });
  } catch (error) {
    const pollingIntervalId = path(['App', 'pollingIntervalId'], getStore());
    if (pollingIntervalId) {
      haltPolling(pollingIntervalId);
    }
    dispatch({
      type: t.GET_PROFILE_FAIL,
      error,
    });
  }
};

export const loginRecaptcha = result => ({
  type: t.LOGIN_RECAPTCHA,
  result,
});
export const login = (email, password, rememberMe, recaptchaKey,isBusinessSubscription) => {
  if (!email.length) {
    return {
      type: t.LOGIN_FAIL,
      validationError: 'MISSING_EMAIL',
    };
  } else if (!isValidEmail(email)) {
    return {
      type: t.LOGIN_FAIL,
      validationError: 'INVALID_EMAIL',
    };
  } else if (!password.length) {
    return {
      type: t.LOGIN_FAIL,
      validationError: 'PASSWORD_REQUIRED',
    };
  } else if (!recaptchaKey && recaptchaKey && recaptchaKey.length) {
    return {
      type: t.LOGIN_FAIL,
      validationError: 'RECAPTCHA_REQUIRED',
    };
  } else if (rememberMe) {
    // TODO: remembering functionality
  }

  return async (dispatch, getStore, client) => {
    dispatch({ type: t.LOGIN });

    try {
      const result = await client.post('/auth/login', {
        data: {
          email,
          password,
          rememberMe,
          recaptchaKey,
          weblogin: true,
          isBusinessSubscription
        },
      });

      dispatch({
        type: t.LOGIN_SUCCESS,
        result,
      });
    } catch (error) {
      dispatch({
        type: t.LOGIN_FAIL,
        error,
      });
    }
  };
};

export const logout = () => async (dispatch, getStore, client) => {
  const pollingIntervalId = path(['App', 'pollingIntervalId'], getStore());
  haltPolling(pollingIntervalId);
  dispatch({ type: t.LOGOUT });
  try {
    await client.get('/auth/logout');
    dispatch({ type: t.LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({
      type: t.LOGOUT_FAIL,
      error,
    });
  }
};

export const deleteAccountSetCheckbox = bool => ({
  type: t.DELETE_ACCOUNT_SET_CHECKBOX,
  bool,
});

export const deleteAccount = (email, reason) => async (dispatch, getStore, client) => {
  const pollingIntervalId = path(['App', 'pollingIntervalId'], getStore());
  haltPolling(pollingIntervalId);
  dispatch({ type: t.DELETE_ACCOUNT });

  try {
    await client.post('/auth/delete-account', { data: { email, reason } });
    return dispatch({ type: t.DELETE_ACCOUNT_SUCCESS });
  } catch (error) {
    dispatch({
      type: t.DELETE_ACCOUNT_FAIL,
      error,
    });
  }
};

export const forgotPasswordRecaptcha = result => ({
  type: t.FORGOT_PASSWORD_RECAPTCHA,
  result,
});

export const forgotPassword = ({ email, recaptchaKey }) => async (dispatch, getStore, client) => {
  dispatch({ type: t.FORGOT_PASSWORD });

  try {
    await client.post('/auth/forgot-password', {
      data: {
        email,
        recaptchaKey,
        weblogin: true,
      },
    });

    dispatch({ type: t.FORGOT_PASSWORD_SUCCESS });
  } catch (error) {
    dispatch({
      type: t.FORGOT_PASSWORD_FAIL,
      error,
    });
  }
};

export const setForgotPasswordError = error => ({
  type: t.FORGOT_PASSWORD_ERROR,
  error: error ? [error] : null,
});

export const sendEmailVerification = email => async (dispatch, getState, client) => {
  dispatch({ type: t.SEND_EMAIL_VERIFICATION });

  try {
    await client.post('/auth/send-email-verification', { data: { email } });

    dispatch({ type: t.SEND_EMAIL_VERIFICATION_SUCCESS });
  } catch (error) {
    dispatch({
      type: t.SEND_EMAIL_VERIFICATION_FAIL,
      error,
    });
  }
};

export const registrationClearData = () => ({
  type: t.REGISTRATION_DATA_CLEAR,
});

export const registrationSignupEmail = result => ({
  type: t.REGISTRATION_EMAIL,
  result,
});

export const registrationSignupPassword = result => ({
  type: t.REGISTRATION_PASSWORD,
  result,
});

export const registrationSignupPasswordConfirmation = result => ({
  type: t.REGISTRATION_PASSWORD_CONFIRMATION,
  result,
});

export const registrationSignupTermsAndConditions = result => ({
  type: t.REGISTRATION_TERMS_AND_CONDITIONS,
  result,
});

export const registrationSignupRecaptcha = result => ({
  type: t.REGISTRATION_RECAPTCHA,
  result,
});

export const invalidateRecaptcha = () => ({
  type: t.INVALIDATE_RECAPTCHA,
});

export const registrationSignupSubmit = ({
  email,
  password,
  recaptchaKey,
}) =>
  // REGISTRATION_SIGNUP
  async (dispatch, getStore, client) => {
    dispatch({ type: t.REGISTRATION_SIGNUP });

    try {
      const result = await client.post('/auth/signup', {
        data: {
          email,
          password,
          recaptchaKey,
        },
      });

      dispatch({
        type: t.REGISTRATION_SUCCESS,
        result,
      });
      const store = getStore();
      const planSelected = store && store[NAME] && store[NAME].planSelected;
      const accountType = planSelected === 'premium' ? 'professional' : planSelected;
      if (['standard', 'professional'].includes(accountType)) {
        dispatch(push({
          pathname: '/register/billing-information',
          search: `?type=${accountType}`,
        }));
      }
      if (accountType === 'free') {
        dispatch(push({
          pathname: '/register/completion',
          search: `?type=${accountType}`,
        }));
      }
    } catch (error) {
      dispatch({
        type: t.USER_SERVICE_REGISTRATION_FAIL,
        error,
      });
    }
  };

export const registrationFail = registrationValidationError => ({
  type: t.REGISTRATION_FAIL,
  registrationValidationError,
});

export const changePassword = ({ oldPassword, newPassword, passwordConfirm }) => {
  const action = {};
  const errors = {};

  if (!oldPassword || !oldPassword.length) {
    errors.oldPassword = 'MISSING_OLD_PASSWORD';
  }

  if (!newPassword || !newPassword.length) {
    errors.newPassword = 'MISSING_NEW_PASSWORD';
  } else if (!isValidPassword(newPassword)) {
    errors.newPassword = 'PASSWORD_LENGTH_INVALID';
  } else if (newPassword !== passwordConfirm) {
    errors.passwordConfirm = 'PASSWORDS_MUST_MATCH';
  }

  if (Object.keys(errors).length) {
    action.type = t.CHANGE_PASSWORD_FAIL;
    action.validationErrors = errors;

    return action;
  }

  return async (dispatch, getStore, client) => {
    dispatch({ type: t.CHANGE_PASSWORD });

    try {
      await client.post('/auth/change-password', {
        data: {
          newPassword,
          oldPassword,
          passwordConfirm,
        },
      });

      dispatch({ type: t.CHANGE_PASSWORD_SUCCESS });
    } catch (error) {
      dispatch({
        type: t.CHANGE_PASSWORD_FAIL,
        error,
      });
    }
  };
};

export const updateProfile = data => async (dispatch, getStore, client) => {
  const errors = {};

  const { currentFlightMonitorWirelessEnabled, newEmail } = data;

  if (newEmail && !isValidEmail(newEmail)) {
    errors.email = 'INVALID_EMAIL';
  }

  const dataClone = { ...data };
  const { profile } = dataClone;
  const {
    flightMonitorEmailAddress0,
    flightMonitorEmailEnabled,
    flightMonitorWirelessEnabled,
    flightMonitorWirelessNumber0,
    flightMonitorWirelessServiceCode0,
  } = profile;

  if (flightMonitorEmailAddress0 &&
      flightMonitorEmailAddress0.length) {
    // Validate it if it exists
    if (!isValidEmail(flightMonitorEmailAddress0)) {
      errors.flightMonitorEmail = 'INVALID_EMAIL';
    }
  } else if (flightMonitorEmailEnabled) {
    // Only warn if it doesn't exist and is enabled
    errors.flightMonitorEmail = 'MISSING_EMAIL';
  }


  if (flightMonitorWirelessNumber0 &&
      flightMonitorWirelessNumber0.length) {
    // Validate it if it exists
    if (!isValidPhoneNumber(flightMonitorWirelessNumber0)) {
      errors.flightMonitorWireless = 'INVALID_PHONE';
    }
  } else if (flightMonitorWirelessEnabled) {
    // Only warn if it doesn't exist and is enabled
    errors.flightMonitorWireless = 'MISSING_PHONE';
  }

  if (!validateCarrierAndPhoneState(profile)) {
    errors.flightMonitorWireless = 'CARRIER_OR_PHONE';
  }

  if (!flightMonitorWirelessEnabled &&
      !flightMonitorWirelessServiceCode0 &&
      !flightMonitorWirelessNumber0 &&
      currentFlightMonitorWirelessEnabled) {
    if (errors.flightMonitorWireless === 'CARRIER_OR_PHONE') {
      delete errors.flightMonitorWireless;
    }
  }

  if (flightMonitorWirelessEnabled &&
      !flightMonitorWirelessServiceCode0) {
    errors.flightMonitorWirelessService = 'MISSING_WIRELESS_SERVICE';
  }

  if (Object.keys(errors).length) {
    return dispatch({
      type: t.UPDATE_PROFILE_FAIL,
      validationErrors: errors,
    });
  }
  if (flightMonitorWirelessNumber0) {
    const newNumber = phoneNumberSanitizer(flightMonitorWirelessNumber0);
    const newProfile = {
      ...profile,
      ...{ flightMonitorWirelessNumber0: newNumber },
    };
    dataClone.profile = newProfile;
  }
  dispatch({ type: t.UPDATE_PROFILE });

  try {
    const result = await client.put('/auth/profile', { data: dataClone });
    return dispatch({
      type: t.UPDATE_PROFILE_SUCCESS,
      result,
    });
  } catch (err) {
    return dispatch({
      type: t.UPDATE_PROFILE_FAIL,
      error: err,
    });
  }
};

export const getBraintreeClientToken = () => async (dispatch, getStore, client) => {
  dispatch({ type: t.GET_BRAINTREE_CLIENT_TOKEN });

  try {
    const result = await client.get('/braintree/client-token');

    dispatch({
      type: t.GET_BRAINTREE_CLIENT_TOKEN_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.GET_BRAINTREE_CLIENT_TOKEN_FAIL,
      error,
    });
  }
};

export const getBraintreePlans = () => async (dispatch, getStore, client) => {
  dispatch({ type: t.GET_BRAINTREE_PLANS });

  try {
    const result = await client.get('/braintree/plans');

    dispatch({
      type: t.GET_BRAINTREE_PLANS_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.GET_BRAINTREE_PLANS_FAIL,
      error,
    });
  }
};

export const getPaymentHistory = () => async (dispatch, getStore, client) => {
  dispatch({ type: t.GET_PAYMENT_HISTORY });

  try {
    const result = await client.get('/braintree/history');

    dispatch({
      type: t.GET_PAYMENT_HISTORY_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.GET_PAYMENT_HISTORY_FAIL,
      error,
    });
  }
};

export const getPaymentHistoryDetail = braintreeId => async (dispatch, getStore, client) => {
  dispatch({ type: t.GET_PAYMENT_HISTORY_DETAIL });

  try {
    const result = await client.get(`/braintree/history-detail/${braintreeId}`);

    dispatch({
      type: t.GET_PAYMENT_HISTORY_DETAIL_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.GET_PAYMENT_HISTORY_DETAIL_FAIL,
      error,
    });
  }
};

export const createBraintreeSubscription = data => async (dispatch, getStore, client) => {
  dispatch({ type: t.CREATE_BRAINTREE_SUBSCRIPTION });

  try {
    const result = await client.post('/braintree/subscription', { data });

    return dispatch({
      type: t.CREATE_BRAINTREE_SUBSCRIPTION_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.CREATE_BRAINTREE_SUBSCRIPTION_FAIL,
      error,
    });
  }
};

// data: { userId,
// braintreeId,
// productName: '(creditsAmount),
// flight alert',
// amount(purchaseAmount) }

export const createBraintreeCreditsTransaction = data => async (dispatch, getStore, client) => {
  dispatch({ type: t.CREATE_BRAINTREE_CREDITS_TRANSACTION });

  try {
    const { result } = await client.post('/braintree/credits/purchase', { data });

    return dispatch({
      type: t.CREATE_BRAINTREE_CREDITS_TRANSACTION_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.CREATE_BRAINTREE_CREDITS_TRANSACTION_FAIL,
      error,
    });
  }
};

export const cancelBraintreeSubscription = reason => async (dispatch, getStore, client) => {
  dispatch({ type: t.CANCEL_BRAINTREE_SUBSCRIPTION });
  try {
    const result = await client.del('/braintree/subscription', { data: { reason } });

    dispatch({
      type: t.CANCEL_BRAINTREE_SUBSCRIPTION_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.CANCEL_BRAINTREE_SUBSCRIPTION_FAIL,
      error,
    });
  }
};

export const updateBillingAddress = data => async (dispatch, getStore, client) => {
  dispatch({ type: t.UPDATE_BILLING_ADDRESS });

  try {
    const result = await client.post('/braintree/update-billing-address', { data });

    dispatch({
      type: t.UPDATE_BILLING_ADDRESS_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.UPDATE_BILLING_ADDRESS_FAIL,
      error,
    });
  }
};

export const updateReasonForUnsubscribing = reason => ({
  type: t.UPDATE_REASON_FOR_UNSUBSCRIBING,
  reason,
});

export const updatePaymentInformation = ({ clientToken, email, nonce }) => {
  const putUrl = '/braintree/update-payment-information/';
  const data = {
    clientToken,
    nonce,
    email,
  };

  return async (dispatch, getStore, client) => {
    dispatch({ type: t.UPDATE_PAYMENT_INFORMATION });

    try {
      const result = await client.put(putUrl, { data });

      dispatch({
        type: t.UPDATE_PAYMENT_INFORMATION_SUCCESS,
        result,
      });
    } catch (error) {
      dispatch({
        type: t.UPDATE_PAYMENT_INFORMATION_FAILURE,
        error,
      });
    }
  };
};

export const upgradeSubscription = () => async (dispatch, getStore, client) => {
  dispatch({ type: t.UPGRADE_SUBSCRIPTION });

  try {
    const result = await client.put('/braintree/upgrade-subscription');

    return dispatch({
      type: t.UPGRADE_SUBSCRIPTION_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: t.UPGRADE_SUBSCRIPTION_FAILURE,
      error,
    });
  }
};

// export const updatePaymentInformation = (data) => ({
//   types: [t.UPDATE_PAYMENT_INFORMATION,
// t.UPDATE_PAYMENT_INFORMATION_SUCCESS,
// t.UPDATE_PAYMENT_INFORMATION_FAILURE],
//   promise: (client) => client.post('/braintree/updatePaymentInformation', {data})
// });

export const resetAuthState = () => ({
  type: t.RESET_AUTH_STATE,
});

export const setServerTime = () => ({
  type: t.SET_SERVER_TIME,
  serverTime: moment.utc().unix(),
});

export const displayAccountAttentionDialog = () => ({
  type: t.ATTENTION_DIALOG,
});

export const dismissAccountAttentionDialog = () => ({
  type: t.DISMISS_ATTENTION_DIALOG,
});

export const redirectToOutagePage = () => ({
  type: t.OUTAGE_PAGE_REDIRECT,
});

export const shouldRefresh = bool => ({
  type: t.SHOULD_REFRESH,
  bool,
});

export const handlePlanSelection = plan => ({
  type: t.SELECT_NEW_ACCOUNT_PLAN,
  result: {
    planSelected: plan,
  },
});

export const goToBillingInformation = bool => ({
  type: t.GO_TO_BILLING_INFORMATION,
  bool,
});

export const setRegistrationStage = registrationStage => ({
  type: t.SET_REGISTRATION_STAGE,
  registrationStage,
});

export const setPlanHovered = plan => ({
  type: t.SET_PLAN_HOVERED,
  plan,
});

// registration billing information page
export const termsAndConditionsChecked = bool => ({
  type: t.HAS_CHECKED_TERMS_AND_CONDITIONS,
  bool,
});

export const disableRegistrationBtn = bool => ({
  type: t.REGISTRATION_BILLING_INFO_BTN_DISABLED,
  bool,
});
// end registration billing information page

// registration completion page
export const getFirstTransactionIdForSubscription = braintreeId =>
  async (dispatch, getStore, client) => {
    dispatch({ type: t.GET_FIRST_TRANSACTIONID });

    try {
      const result = await client.get(`/transaction-id/first/${braintreeId}`);

      dispatch({
        type: t.GET_FIRST_TRANSACTIONID_SUCCESS,
        result,
      });
    } catch (error) {
      dispatch({
        type: t.GET_FIRST_TRANSACTIONID_FAIL,
        error,
      });
    }
  };
// end regisration completion page

export const routerPush = url => dispatch => dispatch(push(url)); 

export const updateAdditionalInfoInput = newState => ({
  type: t.UPDATE_ADDITIONAL_INFO_INPUT,
  result: newState,
});

export const updateFormErrorState = result => ({
  type: t.UPDATE_FORM_ERROR_STATE,
  result,
});


export const hostedFieldsDidCreate = (err, hostedFields) =>
  dispatch => dispatch({
    type: t.HOSTED_FIELDS_DID_CREATE,
    result: {
      braintreeSubmitButtonDisabled: false,
      hostedFields,
    },
  });


// dialogToToggle is the key for the specific dialog and it's value is a bool,
// true equals dialog is open
export const toggleAccountProfileDialogs = dialogToToggle => ({
  type: t.TOGGLE_ACCOUNT_PROFILE_DIALOGS,
  result: dialogToToggle,
});

export const accountFormError = registrationValidationError => ({
  type: t.REGISTRATION_FAIL,
  registrationValidationError,
});

export const setRegistrationFlowToken = () => ({
  type: t.REGISTRATION_FLOW_TOKEN,
  result: {
    registrationFlowToken: true,
  },
});

export const deleteRegistrationFlowToken = () => ({
  type: t.DELETE_REGISTRATION_FLOW_TOKEN,
  result: {
    registrationFlowToken: false,
  },
});

export const checkBusinessUserSubscriptionLevel = ( email) => async (dispatch, getStore, client) => {
  dispatch({ type: t.CHECK_BUSINESSUSER });
  try {
    const COOKIE_NAME = "userList";

    //read cookie
    const userList =  readCookieUserList(COOKIE_NAME) ? JSON.parse(readCookieUserList(COOKIE_NAME)) : [];
    const cookieEmail = userList.find(user => user.email === email);
    
    if(cookieEmail){
      //if email exist in cookie dont make api call
      dispatch({ type: t.CHECK_BUSINESSUSER_SUCCESS, result: {isBusinessUser:cookieEmail.isBusinessUser} });
    } else {
      //if email doesn't exist in email than call api and store in cookie
      const result = await client.post('/auth/check-email', {
        data: {
          email:email
        },
      });

      const newUser = { email: email, isBusinessUser: result.isBusinessUser };
      userList.push(newUser);

      createCookie({name:COOKIE_NAME,value:encodeURIComponent(JSON.stringify(userList)),days:30});

      dispatch({ type: t.CHECK_BUSINESSUSER_SUCCESS, result });
    }
  } catch (error) {
    dispatch({
      type: t.CHECK_BUSINESSUSER_ERROR,
      error,
    });
  }
};

